import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative flex h-full w-full items-center p-2" }
const _hoisted_2 = { class: "absolute z-10" }

import { computed, ref } from 'vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'DialogBubble',
  props: {
		showBubble: Boolean,
	},
  setup(__props) {

	const userHidBubble = ref(false);

	const props = __props;

	const classObject = computed(() => ({
		'max-w-40 overflow-visible border': props.showBubble && !userHidBubble.value,
	}));

	function closeBubble() {
		userHidBubble.value = true;
	}

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["z-10 h-20 w-40 max-w-0 overflow-hidden rounded-md bg-ph-background-2 transition-all duration-300 ease-in-out", classObject.value]),
    onClick: closeBubble
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Icon, {
        class: "absolute right-1 top-1 w-fit hover:cursor-pointer",
        type: "close",
        size: "xs"
      }),
      _createElementVNode("p", null, [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass(["bg-ph-background-2", { triangle: __props.showBubble }])
        }, null, 2)
      ])
    ])
  ], 2))
}
}

})