import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-2 flex rounded-md bg-hub-background-3 p-2"
}
const _hoisted_2 = ["href"]

import { TFileMessageEventContent } from '@/model/events/TMessageEvent';
	import { useMatrixFiles } from '@/composables/useMatrixFiles';
	import { FeatureFlag, useSettings } from '@/store/settings';
	import { onMounted, ref } from 'vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'MessageFile',
  props: {
    message: {}
  },
  setup(__props: any) {

	const settings = useSettings();
	const matrixFiles = useMatrixFiles();
	const authMediaUrl = ref<string | undefined>(undefined);

	const props = __props;

	onMounted(async () => (authMediaUrl.value = props.message.url ? await matrixFiles.useAuthorizedMediaUrl(props.message.url, settings.isFeatureEnabled(FeatureFlag.authenticatedMedia)) : undefined));

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!

  return (authMediaUrl.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Icon, {
          type: "paperclip",
          class: "mr-2"
        }),
        _createElementVNode("a", {
          class: "text-blue",
          target: "_blank",
          href: authMediaUrl.value
        }, _toDisplayString(_ctx.message.filename), 9, _hoisted_2)
      ]))
    : _createCommentVNode("", true)
}
}

})