import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, unref as _unref } from "vue"

const _hoisted_1 = { class: "flex items-center gap-4" }
const _hoisted_2 = { class: "grid" }
const _hoisted_3 = {
  key: 1,
  class: "z-0 text-base"
}
const _hoisted_4 = { class: "grid items-center gap-2" }

import Icon from '../elements/Icon.vue';
	import H3 from '../elements/H3.vue';

	import { ref } from 'vue';
	import { router } from '@/core/router';
	import { usePubHubs } from '@/core/pubhubsStore';
	import { useI18n } from 'vue-i18n';
	import { TPublicRoom } from '@/store/rooms';
	import SecuredRoomLogin from '../ui/SecuredRoomLogin.vue';

	type Props = {
		room: TPublicRoom;
		roomIsSecure: boolean;
		memberOfRoom: boolean;
		securedRoomLoginFlow: boolean;
	};

	
export default /*@__PURE__*/_defineComponent({
  __name: 'RoomPill',
  props: {
    room: {},
    roomIsSecure: { type: Boolean },
    memberOfRoom: { type: Boolean },
    securedRoomLoginFlow: { type: Boolean }
  },
  emits: ['toggle-secured-room'],
  setup(__props: any, { emit: __emit }) {

	// Components
	const pubhubs = usePubHubs();
	const { t } = useI18n();
	t;
	const expanded = ref(false);
	const joinedARoom = ref(false);
	const panelOpen = ref(true);

	const props = __props;

	const emit = __emit;

	function expandPillToggle() {
		//In case of secured room, don't expand
		if (!(props.securedRoomLoginFlow && panelOpen)) {
			expanded.value = !expanded.value;
		}
	}

	async function joinRoom() {
		if (props.roomIsSecure && !props.memberOfRoom) {
			// Whenever user is not in the secured room, it means that secure room panel should be visible
			emit('toggle-secured-room'); // sets the props for open panel to true and only for current Room.
			panelOpen.value = true; // Resets the ref so that the panel can be opened and closed multiple times.
		} else {
			joinedARoom.value = true;
			setTimeout(() => {
				pubhubs.joinRoom(props.room.room_id);
			}, 1000);
		}
	}

	function goToRoom() {
		router.push({ name: 'room', params: { id: props.room.room_id } });
	}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "relative flex h-min min-w-56 max-w-[23rem] cursor-pointer rounded-md bg-white-middle text-black dark:bg-gray dark:text-white",
    onClick: _cache[3] || (_cache[3] = ($event: any) => (expandPillToggle()))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["absolute left-0 top-0 h-full w-3 shrink-0 bg-hub-background-5 transition-all duration-1000 ease-in-out", { 'w-full bg-notification': joinedARoom.value }])
    }, null, 2),
    _createElementVNode("div", {
      class: _normalizeClass(["flex h-min w-full justify-between p-2 py-3 pl-5", { 'gap-4': expanded.value }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(Icon, {
          type: _ctx.roomIsSecure ? 'shield' : 'speech_bubbles',
          class: "shrink-0"
        }, null, 8, ["type"]),
        (_ctx.securedRoomLoginFlow && panelOpen.value)
          ? (_openBlock(), _createBlock(SecuredRoomLogin, {
              key: 0,
              securedRoomId: _ctx.room.room_id,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (panelOpen.value = false))
            }, null, 8, ["securedRoomId"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(H3, {
            class: _normalizeClass(["relative z-0 m-0 line-clamp-1 overflow-hidden font-semibold", { 'line-clamp-3': expanded.value && !joinedARoom.value }])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.room?.name), 1)
            ]),
            _: 1
          }, 8, ["class"]),
          (joinedARoom.value === false)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                class: _normalizeClass(["line-clamp-1 text-xs italic", { 'line-clamp-3': expanded.value }])
              }, _toDisplayString(_ctx.room.topic), 3))
            : (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_unref(t)('rooms.joined')), 1))
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.memberOfRoom)
          ? (_openBlock(), _createBlock(Icon, {
              key: 0,
              type: "arrow-right",
              size: "lg",
              class: "min-w-[4rem] hover:cursor-pointer",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (goToRoom()))
            }))
          : _createCommentVNode("", true),
        (!_ctx.memberOfRoom)
          ? (_openBlock(), _createBlock(Icon, {
              key: 1,
              type: "join_room",
              size: "lg",
              class: "hover:cursor-pointer",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (joinRoom()))
            }))
          : _createCommentVNode("", true)
      ])
    ], 2)
  ]))
}
}

})