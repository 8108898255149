import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "relative z-0" }
const _hoisted_2 = {
  key: 0,
  class: "z-100 absolute h-full w-full bg-white opacity-70"
}
const _hoisted_3 = { class: "z-100" }


export default /*@__PURE__*/_defineComponent({
  __name: 'Masked',
  props: {
		masked: {
			type: Boolean,
			default: false,
		},
	},
  setup(__props) {

	const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.masked)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}
}

})