import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, unref as _unref, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, Suspense as _Suspense, isRef as _isRef, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "-mt-2 w-full px-3 pb-3 md:px-6" }
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = {
  key: 2,
  class: "absolute bottom-2 right-0 z-20 xs:right-4 md:right-32"
}
const _hoisted_5 = { class: "flex max-h-12 items-end justify-between gap-2 md:max-h-52" }
const _hoisted_6 = { class: "w-full overflow-hidden rounded-xl bg-hub-background-4 dark:bg-hub-background-4" }
const _hoisted_7 = {
  key: 0,
  class: "flex h-10 items-center justify-between gap-2 px-2"
}
const _hoisted_8 = { class: "flex w-fit gap-2 overflow-hidden" }
const _hoisted_9 = { class: "text-nowrap" }
const _hoisted_10 = { class: "flex items-center gap-3 rounded-md bg-hub-background-3 px-2" }
const _hoisted_11 = { class: "flex min-h-[50px] items-end gap-x-2 rounded-2xl px-2 py-1 dark:bg-hub-background-4" }
const _hoisted_12 = {
  key: 1,
  class: "flex items-center rounded-md bg-gray-light p-2 dark:bg-hub-background"
}
const _hoisted_13 = { class: "ml-2 flex max-w-3xl flex-col justify-between" }
const _hoisted_14 = { class: "font-bold" }
const _hoisted_15 = { class: "mt-2 flex items-center" }
const _hoisted_16 = { class: "italic" }
const _hoisted_17 = { class: "flex items-center gap-2 text-xl" }
const _hoisted_18 = { class: "hidden md:flex" }
const _hoisted_19 = {
  key: 0,
  class: "absolute bottom-[10%] md:left-[40%]",
  id: "yivi-web-form"
}
const _hoisted_20 = { class: "text-black dark:bg-gray-dark dark:text-white" }
const _hoisted_21 = ["accept"]

import Popover from '../ui/Popover.vue';
	import TextArea from './TextArea.vue';
	import Button from '../elements/Button.vue';
	import Icon from '../elements/Icon.vue';
	import EmojiPicker from '../ui/EmojiPicker.vue';
	import Mention from '../ui/Mention.vue';
	import UploadPicker from '../ui/UploadPicker.vue';
	import SignedMessageButton from '../ui/SignedMessageButton.vue';
	import Line from '../elements/Line.vue';
	import FileUploadDialog from '../ui/FileUploadDialog.vue';
	import MessageSnippet from '../rooms/MessageSnippet.vue';

	import { useFormInputEvents, usedEvents } from '@/composables/useFormInputEvents';
	import { useMatrixFiles } from '@/composables/useMatrixFiles';
	import filters from '@/core/filters';
	import { usePubHubs } from '@/core/pubhubsStore';
	import { useMessageActions } from '@/store/message-actions';
	import { useRooms } from '@/store/store';
	import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
	import { useRoute } from 'vue-router';
	import { YiviSigningSessionResult } from '@/lib/signedMessages';
	import { TMessageEvent } from '@/model/events/TMessageEvent';

	import Room from '@/model/rooms/Room';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'MessageInput',
  props: {
    room: {}
  },
  emits: usedEvents,
  setup(__props: any, { emit: __emit }) {

	// Components
	const route = useRoute();
	const rooms = useRooms();
	const pubhubs = usePubHubs();
	const messageActions = useMessageActions();

	const emit = __emit;
	const { value, reset, changed, cancel } = useFormInputEvents(emit);
	const { allTypes, getTypesAsString } = useMatrixFiles();

	const buttonEnabled = ref<boolean>(false);
	const showPopover = ref<boolean>(false);
	const signingMessage = ref<boolean>(false);
	const showEmojiPicker = ref<boolean>(false);
	const showMention = ref<boolean>(true); // Mentions may always be shown, except when another popup is shown
	const showFileUploadDialog = ref<boolean>(false);
	const fileUploading = ref<boolean>(false); // to hide other dialogs while in the file upload process
	const fileInfo = ref<File>();
	const uri = ref<string>('');
	const caretPos = ref({ top: 0, left: 0 });

	const selectedAttributesSigningMessage = ref<string[]>(['irma-demo.sidn-pbdf.email.domain']);

	const elFileInput = ref<HTMLInputElement | null>(null);
	const elTextInput = ref<InstanceType<typeof TextArea> | null>(null);
	const inReplyTo = ref<TMessageEvent | undefined>(undefined);
	

	const sendMessageText = computed(() => {
		if (signingMessage.value) {
			return 'message.sign.send';
		}
		return 'message.send';
	});

	watch(route, () => {
		reset();
		toggleMenus(undefined);
	});

	onMounted(() => {
		window.addEventListener('keydown', handleKeydown);
		reset();
	});

	onUnmounted(() => {
		window.removeEventListener('keydown', handleKeydown);
	});

	// Focus on message input if the state of messageActions changes (for example, when replying).
	messageActions.$subscribe(async () => {
		inReplyTo.value = messageActions.replyingTo ? ((await pubhubs.getEvent(rooms.currentRoomId, messageActions.replyingTo)) as TMessageEvent) : undefined;
		elTextInput.value?.$el.focus();
	});

	function clickedEmoticon(emoji: string) {
		value.value += emoji;
		elTextInput.value?.$el.focus();
		checkButtonState();
	}

	function handleKeydown(event: KeyboardEvent) {
		toggleMenus(undefined);
		if (event.key === 'Escape') {
			signingMessage.value = false;
			showMention.value = false;
		}
	}

	function togglePopover() {
		closeReplyingTo();
		toggleMenus(showPopover);
	}

	function toggleEmojiPicker() {
		toggleMenus(showEmojiPicker);
	}

	function toggleSigningMessage(newValue: boolean) {
		signingMessage.value = newValue;
		setCaretPos({ top: 0, left: caretPos.value.left });
		toggleMenus(undefined);
	}

	function isValidMessage(): boolean {
		// TextAreas always return strings, so the message is valid to send if it is a string with a length > 0
		return typeof value.value === 'string' && value.value.trim().length > 0;
	}

	function checkButtonState() {
		buttonEnabled.value = isValidMessage();
	}

	function uploadFile(event: Event) {
		const target = event.currentTarget as HTMLInputElement;
		const file = target.files && target.files[0];
		if (file) {
			// Once the file has been selected from the filesystem.
			// Set props to be passed to the component.
			fileInfo.value = file;
			uri.value = URL.createObjectURL(file);
			// display the component.
			showFileUploadDialog.value = true;
			// Inspiration from  https://dev.to/schirrel/vue-and-input-file-clear-file-or-select-same-file-24do
			const inputElement = elFileInput.value;
			if (inputElement) inputElement.value = '';
		}
	}

	function clickedAttachment() {
		fileUploading.value = true;
		elFileInput.value?.click();
	}

	//  To autocomplete the mention user in the message.
	function mentionUser(user: any) {
		let userMention = user.rawDisplayName;

		// Make sure pseudonym is included if it hasn't
		if (!filters.extractPseudonymFromString(userMention)) {
			userMention += ' - ' + filters.extractPseudonym(user.userId);
		}

		let message = value.value?.toString();
		if (message?.lastIndexOf('@') !== -1) {
			const lastPosition = message?.lastIndexOf('@');
			message = message?.substring(0, lastPosition);
			value.value = message + ' @' + userMention;
		} else {
			value.value += ' @' + userMention;
		}
	}

	function submitMessage() {
		if (!isValidMessage()) {
			return;
		} // This makes sure value.value is not undefined

		if (signingMessage.value) {
			signMessage(value.value!.toString(), selectedAttributesSigningMessage.value);
		} else if (messageActions.replyingTo && inReplyTo.value) {
			pubhubs.addMessage(rooms.currentRoomId, value.value!.toString(), inReplyTo.value);
			messageActions.replyingTo = undefined;
		} else {
			pubhubs.addMessage(rooms.currentRoomId, value.value!.toString());
		}

		value.value = '';
	}

	function signMessage(message: string, attributes: string[]) {
		rooms.yiviSignMessage(message, attributes, rooms.currentRoomId, finishedSigningMessage);
	}

	function finishedSigningMessage(result: YiviSigningSessionResult) {
		pubhubs.addSignedMessage(rooms.currentRoomId, result);
		signingMessage.value = false;
	}

	function setCaretPos(pos: { top: number; left: number }) {
		caretPos.value = pos;
	}

	// display of menus is dependant of each other.
	// so we pass the boolean that controls the visibility of a dialog and we toggle that boolean
	// the other dialogs are hidden
	function toggleMenus(from: object | undefined) {
		showPopover.value = Object.is(from, showPopover) ? !showPopover.value : false;
		showEmojiPicker.value = Object.is(from, showEmojiPicker) ? !showEmojiPicker.value : false;
		showMention.value = Object.is(from, showMention) ? true : !fileUploading.value && !showPopover.value && !showEmojiPicker.value; // either true (from focus) or dependent of other popups
		elFileInput.value = null;
	}

	function closeReplyingTo() {
		messageActions.replyingTo = undefined;
	}

return (_ctx: any,_cache: any) => {
  const _directive_focus = _resolveDirective("focus")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (showPopover.value)
        ? (_openBlock(), _createBlock(Popover, {
            key: 0,
            onClose: togglePopover,
            class: "absolute bottom-2"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(UploadPicker, { onClick: clickedAttachment }),
                (!signingMessage.value)
                  ? (_openBlock(), _createBlock(SignedMessageButton, {
                      key: 0,
                      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (toggleSigningMessage(true)), ["stop"]))
                    }))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (showMention.value)
        ? (_openBlock(), _createBlock(Mention, {
            key: 1,
            msg: _unref(value),
            top: caretPos.value.top,
            left: caretPos.value.left,
            room: _ctx.room,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (mentionUser($event)))
          }, null, 8, ["msg", "top", "left", "room"]))
        : _createCommentVNode("", true),
      (showEmojiPicker.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(EmojiPicker, {
              onEmojiSelected: clickedEmoticon,
              onClose: toggleEmojiPicker
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        (inReplyTo.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('message.in_reply_to')), 1),
                (_openBlock(), _createBlock(_Suspense, null, {
                  fallback: _withCtx(() => [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("p", null, _toDisplayString(_ctx.$t('state.loading_message')), 1)
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(MessageSnippet, {
                      eventId: _unref(messageActions).replyingTo,
                      room: _ctx.room
                    }, null, 8, ["eventId", "room"])
                  ]),
                  _: 1
                }))
              ]),
              _createElementVNode("button", {
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(messageActions).replyingTo = undefined))
              }, [
                _createVNode(Icon, {
                  type: "closingCross",
                  size: "sm"
                })
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(Icon, {
            class: "mb-2 border-r-2 border-r-gray-light pr-3 dark:text-white",
            type: "paperclip",
            onClick: _withModifiers(togglePopover, ["stop"]),
            asButton: true
          }),
          _withDirectives(_createVNode(TextArea, {
            ref_key: "elTextInput",
            ref: elTextInput,
            class: "max-h-40 overflow-x-hidden border-none bg-transparent placeholder:text-gray-dark dark:placeholder:text-gray-lighter md:max-h-60",
            placeholder: _ctx.$t('rooms.new_message'),
            title: _ctx.$t('rooms.new_message'),
            modelValue: _unref(value),
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
            onChanged: _cache[4] || (_cache[4] = ($event: any) => {
							_unref(changed)();
							checkButtonState();
						}),
            onSubmit: _cache[5] || (_cache[5] = ($event: any) => (submitMessage())),
            onCancel: _cache[6] || (_cache[6] = ($event: any) => (_unref(cancel)())),
            onCaretPos: setCaretPos
          }, null, 8, ["placeholder", "title", "modelValue"]), [
            [_directive_focus]
          ]),
          _createVNode(Icon, {
            class: "mb-2 dark:text-white",
            type: "emoticon",
            onClick: _withModifiers(toggleEmojiPicker, ["stop"]),
            asButton: true
          })
        ]),
        (signingMessage.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createVNode(Icon, {
                type: "sign",
                size: "base",
                class: "mt-1 self-start"
              }),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("h3", _hoisted_14, _toDisplayString(_ctx.$t('message.sign.heading')), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('message.sign.info')), 1),
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(Icon, {
                    type: "warning",
                    size: "sm",
                    class: "mb-[2px] mr-2 mt-1 shrink-0 self-start"
                  }),
                  _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.$t('message.sign.warning')), 1)
                ]),
                _createVNode(Line, { class: "mb-2" }),
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('message.sign.selected_attributes')), 1),
                _cache[11] || (_cache[11] = _createElementVNode("div", { class: "mt-1 flex w-20 justify-center rounded-full bg-black text-white" }, [
                  _createElementVNode("p", null, "Email")
                ], -1))
              ]),
              _createVNode(Icon, {
                type: "closingCross",
                size: "sm",
                asButton: true,
                onClick: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (toggleSigningMessage(false)), ["stop"])),
                class: "ml-auto self-start"
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(Button, {
        class: "flex h-fit min-h-[50px] rounded-xl",
        disabled: !buttonEnabled.value,
        onClick: submitMessage
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_17, [
            _createVNode(Icon, {
              type: "talk",
              size: "sm",
              class: "shrink-0 rotate-45 -scale-100"
            }),
            _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t(sendMessageText.value)), 1)
          ])
        ]),
        _: 1
      }, 8, ["disabled"]),
      (signingMessage.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_19))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_20, [
      (showFileUploadDialog.value)
        ? (_openBlock(), _createBlock(FileUploadDialog, {
            key: 0,
            file: fileInfo.value,
            blobURL: uri.value,
            onClose: _cache[8] || (_cache[8] = ($event: any) => {
					showFileUploadDialog.value = false;
					fileUploading.value = false;
				})
          }, null, 8, ["file", "blobURL"]))
        : _createCommentVNode("", true),
      _createElementVNode("input", {
        type: "file",
        accept: _unref(getTypesAsString)(_unref(allTypes)),
        class: "attach-file",
        ref_key: "elFileInput",
        ref: elFileInput,
        onChange: _cache[9] || (_cache[9] = ($event: any) => (uploadFile($event))),
        onCancel: _cache[10] || (_cache[10] = ($event: any) => (fileUploading.value = false)),
        hidden: ""
      }, null, 40, _hoisted_21)
    ])
  ]))
}
}

})