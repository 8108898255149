import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "yivi-login",
  class: "w-[255px] after:absolute after:-top-[1.2em] after:left-[50%] after:border-[1.25em] after:border-r-0 after:border-t-0 after:border-transparent after:border-b-white after:drop-shadow-[0px_-5px_16px_rgb(0,0,0,0.15)]"
}

import { SecuredRoomAttributeResult } from '@/dev/types';
	import { useRouter } from 'vue-router';
	import { useRooms } from '@/store/rooms';
	import { usePubHubs } from '@/core/pubhubsStore';
	import { onMounted } from 'vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'SecuredRoomQR',
  props: {
    securedRoomId: {}
  },
  emits: ["error"],
  setup(__props: any, { emit: __emit }) {

	const props = __props;

	const rooms = useRooms();
	const pubhubs = usePubHubs();
	const router = useRouter();

	onMounted(() => rooms.yiviSecuredRoomflow(props.securedRoomId, resultOfEntry));

	const emit = __emit;

	function resultOfEntry(result: SecuredRoomAttributeResult) {
		if (result.goto) {
			pubhubs.updateRooms().then(() => router.push({ name: 'room', params: { id: props.securedRoomId } }));
		} else if (result.not_correct) {
			emit('error', result.not_correct);
		}
	}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1))
}
}

})