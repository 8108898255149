import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

import { FeatureFlag, useSettings } from '@/store/settings';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'SignedMessageButton',
  setup(__props) {

	const settings = useSettings();
	const signedMessagesEnabled = settings.isFeatureEnabled(FeatureFlag.signedMessages);

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!

  return (_unref(signedMessagesEnabled))
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        class: "m-2 flex flex-col items-center",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click', $event)))
      }, [
        _createVNode(_component_Icon, {
          class: "dark:text-white",
          type: "sign"
        }),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('message.sign.add_signature')), 1)
      ]))
    : _createCommentVNode("", true)
}
}

})