import { defineComponent as _defineComponent } from 'vue'
import { vModelText as _vModelText, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "flex h-80 w-full max-w-80 flex-col rounded-2xl bg-lightgray-light p-4 dark:bg-gray-darker" }
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = { class: "flex flex-row gap-2 border-b border-gray-light py-3" }
const _hoisted_4 = { class: "scrollbar emoji-font flex flex-wrap gap-2 overflow-y-auto pr-2" }
const _hoisted_5 = ["onClick"]

import { onMounted, ref, computed } from 'vue';

	import { useSettings } from '@/store/settings';

	import { Emoji } from 'emojibase';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'EmojiPicker',
  emits: ['close', 'emojiSelected'],
  setup(__props, { emit: __emit }) {

	const settings = useSettings();
	const language = settings.getActiveLanguage;

	// Fetching data file for emoji from localized dataset.
	const data = require(`emojibase-data/${language}/data.json`);

	const emojis = ref([] as Emoji[]);
	const searchQuery = ref('');
	const selectedGroup = ref(0);
	const emit = __emit;

	// Update this with new icons.
	const imageList = [
		'emoji_clock',
		'', /// Empty because there are two categories of smileys and we merge them.
		'emoji_smiley',
		'emoji_bear',
		'emoji_cup',
		'emoji_house',
		'emoji_basketball',
		'emoji_lightbulb',
		'emoji_signs',
		'emoji_flag',
	];

	onMounted(async () => {
		try {
			emojis.value = data.filter((emoji: Emoji) => {
				return !emoji.label.includes('regional');
			});
		} catch (error) {
			console.error('An error occurred while fetching the emojis:', error);
		}
	});

	const filterEmojis = computed(() => {
		let filtered = emojis.value;

		if (selectedGroup.value) {
			filtered = emojis.value.filter((emoji) => emoji.group === selectedGroup.value);

			// only smileys
			if (selectedGroup.value === 2) {
				const onlySmiley = emojis.value.filter((emoji) => {
					return emoji.group === 1;
				});
				filtered = onlySmiley.concat(filtered);
				const withAdditionalSmiles = emojis.value.filter((emoji) => {
					return emoji.group === 0;
				});
				filtered = withAdditionalSmiles.concat(filtered);
			}
		}

		if (searchQuery.value) {
			filtered = filtered.filter((emoji) => {
				const searchCriterion = emoji.shortcodes ? emoji.shortcodes[0] : emoji.label;
				return searchCriterion.toLowerCase().includes(searchQuery.value.toLowerCase());
			});
		}

		return filtered;
	});

	function selectEmojiByGroup(group: number = 0) {
		selectedGroup.value = group;
	}

	function selectEmoji(emoji: Emoji) {
		emit('emojiSelected', emoji.emoji);
	}

	function groupLabel() {
		const labels = [
			// Concatenate group 0,1,2 in the same group as they are similar in nature.
			'clock', //0
			'', //1
			'smiley', //2
			'bear', //3
			'cup', //4
			'house', //5
			'basketball', //6
			'lightbulb', //7
			'signs', //8
			'flag', //9
		];

		return labels[selectedGroup.value];
	}

	async function close() {
		emit('close');
	}

	function preventClose() {
		// Does nothing, but needed to add this comment:
		// @keydown.stop attribute on the input is needed for preventing to close the popup
		// Due to the MessageInput component that sees every keystroke as a reason to close all popups.
	}

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      class: "h-7 w-full rounded placeholder:text-base dark:bg-gray-middle dark:text-white dark:placeholder:text-white",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchQuery).value = $event)),
      type: "text",
      placeholder: _ctx.$t('others.search'),
      onKeydown: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (preventClose()), ["stop"]))
    }, null, 40, _hoisted_2), [
      [_vModelText, searchQuery.value]
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(imageList, (image, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
          (index !== 1)
            ? (_openBlock(), _createBlock(_component_Icon, {
                key: 0,
                class: _normalizeClass([{ 'border-b-2': selectedGroup.value === index }, "w-6 cursor-pointer pb-1"]),
                onClick: ($event: any) => (index === 0 || index === 1 ? selectEmojiByGroup() : selectEmojiByGroup(index)),
                type: image
              }, null, 8, ["class", "onClick", "type"]))
            : _createCommentVNode("", true)
        ], 64))
      }), 64))
    ]),
    _createElementVNode("p", null, _toDisplayString(_ctx.$t('emoji.' + groupLabel())), 1),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filterEmojis.value, (emoji) => {
        return (_openBlock(), _createElementBlock("span", {
          key: emoji.hexcode,
          onClick: ($event: any) => (selectEmoji(emoji)),
          class: "flex cursor-pointer items-center justify-center overflow-hidden text-xl"
        }, _toDisplayString(emoji.emoji), 9, _hoisted_5))
      }), 128))
    ])
  ])), [
    [_directive_click_outside, close]
  ])
}
}

})