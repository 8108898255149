import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, mergeProps as _mergeProps, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["onClick"]

import { ref, computed } from 'vue';
	import { FilteredListEvent } from '@/types/components';

	type Props = {
		items: Array<Record<string, any>>;
		filterKey?: string[];
		minLength?: number;
		listTop?: boolean;
		showCompleteList?: boolean;
		inputClass?: string;
		listClass?: string;
		sortby: string;
		placeholder: string;
	};

	
export default /*@__PURE__*/_defineComponent({
  __name: 'FilteredList',
  props: {
    items: { default: () => [] },
    filterKey: { default: () => ['name'] },
    minLength: { default: 1 },
    listTop: { type: Boolean, default: false },
    showCompleteList: { type: Boolean, default: true },
    inputClass: { default: '' },
    listClass: { default: '' },
    sortby: { default: '' },
    placeholder: { default: 'Filter' }
  },
  emits: ['click', 'filter'],
  setup(__props: any, { emit: __emit }) {

	const emit = __emit;

	const filter = ref('');

	const props = __props;

	const filteredItems = computed(() => {
		let items = props.items;
		if (filter.value.length >= props.minLength) {
			const lcFilter = filter.value.toLowerCase();
			items = props.items.filter((item: any) => {
				if (filter.value === '') {
					return true;
				}
				for (const filterKey of props.filterKey) {
					const lcItem = item[filterKey]?.toLowerCase();
					if (lcItem && lcItem.includes(lcFilter)) return true;
				}
				return false;
			});
			if (props.sortby !== '') {
				items = items.toSorted((a: Record<string, any>, b: Record<string, any>) => {
					return a[props.sortby].toLowerCase().localeCompare(b[props.sortby].toLowerCase());
				});
			}
		} else {
			if (!props.showCompleteList) {
				items = [];
			}
		}
		return items;
	});

	function changed() {
		filter.value = filter.value.toLocaleLowerCase();
		const event: FilteredListEvent = {
			filter: filter.value,
			length: filteredItems.value.length,
		};
		emit('filter', event);
	}

	function clickedItem(item: any) {
		filter.value = '';
		emit('click', item);
	}

return (_ctx: any,_cache: any) => {
  const _component_TextInput = _resolveComponent("TextInput")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.listTop)
      ? (_openBlock(), _createBlock(_component_TextInput, {
          key: 0,
          placeholder: _ctx.placeholder,
          modelValue: filter.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filter).value = $event)),
          class: _normalizeClass(["mb-4 w-full", _ctx.inputClass]),
          onInput: _cache[1] || (_cache[1] = ($event: any) => (changed()))
        }, null, 8, ["placeholder", "modelValue", "class"]))
      : _createCommentVNode("", true),
    (filteredItems.value.length > 0)
      ? (_openBlock(), _createElementBlock("ul", {
          key: 1,
          class: _normalizeClass(_ctx.listClass)
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredItems.value, (item, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              class: "group block cursor-pointer rounded p-1 hover:bg-lightgray hover:dark:bg-gray-dark",
              onClick: ($event: any) => (clickedItem(item))
            }, [
              _renderSlot(_ctx.$slots, "item", _mergeProps({ ref_for: true }, { item }))
            ], 8, _hoisted_1))
          }), 128))
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.listTop)
      ? (_openBlock(), _createBlock(_component_TextInput, {
          key: 2,
          placeholder: _ctx.placeholder,
          modelValue: filter.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((filter).value = $event)),
          class: _normalizeClass(["mt-4 w-full", _ctx.inputClass]),
          onInput: _cache[3] || (_cache[3] = ($event: any) => (changed()))
        }, null, 8, ["placeholder", "modelValue", "class"]))
      : _createCommentVNode("", true)
  ]))
}
}

})