import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, normalizeClass as _normalizeClass, Suspense as _Suspense } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "flex flex-wrap items-center overflow-hidden text-wrap break-all" }
const _hoisted_3 = { class: "relative flex min-h-6 w-full items-start gap-x-1" }
const _hoisted_4 = { class: "flex flex-grow flex-wrap items-start" }
const _hoisted_5 = {
  class: "inline-block",
  style: {"margin-top":"-2px"}
}
const _hoisted_6 = {
  class: "mx-1 inline-block",
  style: {"margin-top":"1px"}
}
const _hoisted_7 = { class: "flex gap-x-1" }
const _hoisted_8 = ["title"]
const _hoisted_9 = ["title"]
const _hoisted_10 = ["title"]
const _hoisted_11 = { class: "flex items-center gap-3 rounded-md bg-hub-background-3 px-2" }

import MessageSnippet from './MessageSnippet.vue';
	import Message from './Message.vue';
	import MessageFile from './MessageFile.vue';
	import MessageImage from './MessageImage.vue';
	import MessageSigned from './MessageSigned.vue';
	import RoomEventActionsPopup from './RoomEventActionsPopup.vue';
	import Avatar from '../ui/Avatar.vue';
	import EventTime from './EventTime.vue';
	import ProfileAttributes from './ProfileAttributes.vue';
	import UserDisplayName from './UserDisplayName.vue';
	import Icon from '../elements/Icon.vue';

	import { usePubHubs } from '@/core/pubhubsStore';
	import { router } from '@/core/router';
	import { TMessageEvent } from '@/model/events/TMessageEvent';
	import Room from '@/model/rooms/Room';
	import { useConnection } from '@/store/connection';
	import { useMessageActions } from '@/store/message-actions';
	import { PluginType } from '@/store/plugins';
	import { RoomType } from '@/store/rooms';
	import { FeatureFlag, useSettings } from '@/store/settings';
	import { useUser } from '@/store/user';
	import { computed, ref } from 'vue';

	// Stores
	type Props = {
		event: TMessageEvent;
		room: Room;
		deleteMessageDialog?: boolean;
	};
	
export default /*@__PURE__*/_defineComponent({
  __name: 'RoomEvent',
  props: {
    event: {},
    room: {},
    deleteMessageDialog: { type: Boolean, default: false }
  },
  emits: ["inReplyToClick", "deleteMessage"],
  setup(__props: any, { emit: __emit }) {

	// Components
	const connection = useConnection();
	const messageActions = useMessageActions();
	const user = useUser();
	const settings = useSettings();

	const props = __props;

	const roomMember = props.room.getMember(props.event.sender, true);
	if (!roomMember) throw new Error('Sender of event not found while trying to display event.');

	const inReplyToId = props.event.content['m.relates_to']?.['m.in_reply_to']?.event_id;

	const emit = __emit;

	const msgIsNotSend = computed(() => {
		return props.event.event_id.substring(0, 1) === '~';
	});

	const redactedMessage = computed(() => {
		const inRedactedMessageIds = props.room.inRedactedMessageIds(props.event.event_id);
		const containsRedactedBecause = props.event.unsigned?.redacted_because != undefined;
		// Remove the event id from the list with redacted event IDs if the event already contains the redacted_because key
		if (inRedactedMessageIds && containsRedactedBecause) {
			props.room.removeRedactedEventId(props.event.event_id);
		}
		return inRedactedMessageIds || containsRedactedBecause;
	});

	function onInReplyToClick() {
		if (!inReplyToId) return;
		emit('inReplyToClick', inReplyToId);
	}

	function onDeleteMessage(event: TMessageEvent) {
		emit('deleteMessage', event);
	}

	function reply() {
		messageActions.replyingTo = undefined;
		messageActions.replyingTo = props.event.event_id;
	}

	function resend() {
		const pubhubs = usePubHubs();
		pubhubs.resendEvent(props.event);
	}

	// Waits for checking if message is realy send. Otherwise a 'resend' button appears. See also msgIsNotSend computed.
	const timerReady = ref(false);
	window.setTimeout(() => {
		timerReady.value = true;
	}, 1000);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.event.plugin && _ctx.event.plugin.plugintype === _unref(PluginType).EVENT && _ctx.event.type === _ctx.event.plugin.type)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.event.plugin.component), { event: _ctx.event }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.event.plugin.component), 1)
            ]),
            _: 1
          }, 8, ["event"]))
        ]))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["group flex gap-4 px-3 py-4 md:px-6", { 'transition-all duration-150 ease-in-out hover:bg-lightgray-light hover:dark:bg-hub-background-2': !_ctx.deleteMessageDialog, 'mx-4 rounded shadow-[0_0_5px_0_rgba(0,0,0,0.3)]': props.deleteMessageDialog }])
        }, [
          _createVNode(Avatar, { user: _unref(roomMember) }, null, 8, ["user"]),
          _createElementVNode("div", {
            class: _normalizeClass({ 'w-5/6': _ctx.deleteMessageDialog, 'w-4/5 xl:w-3/5': !_ctx.deleteMessageDialog })
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("span", _hoisted_5, [
                    _createVNode(UserDisplayName, {
                      user: _ctx.event.sender,
                      room: _ctx.room
                    }, null, 8, ["user", "room"])
                  ]),
                  _createElementVNode("span", _hoisted_6, [
                    _createElementVNode("span", _hoisted_7, [
                      _cache[3] || (_cache[3] = _createElementVNode("span", { class: "text-xs font-normal" }, "|", -1)),
                      _createVNode(EventTime, {
                        timestamp: _ctx.event.origin_server_ts,
                        showDate: false
                      }, null, 8, ["timestamp"]),
                      _cache[4] || (_cache[4] = _createElementVNode("span", { class: "text-xs font-normal" }, "|", -1)),
                      _createVNode(EventTime, {
                        timestamp: _ctx.event.origin_server_ts,
                        showDate: true
                      }, null, 8, ["timestamp"])
                    ])
                  ]),
                  (props.room.getType() == _unref(RoomType).PH_MESSAGES_RESTRICTED)
                    ? (_openBlock(), _createBlock(ProfileAttributes, {
                        key: 0,
                        class: "inline-block",
                        user: _ctx.event.sender,
                        room_id: _ctx.event.room_id
                      }, null, 8, ["user", "room_id"]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", null, [
                  (timerReady.value && !_ctx.deleteMessageDialog)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (msgIsNotSend.value && _unref(connection).isOn)
                          ? (_openBlock(), _createElementBlock("button", {
                              key: 0,
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (resend())),
                              class: "mb-1 ml-2",
                              title: _ctx.$t('errors.resend')
                            }, [
                              _createVNode(Icon, {
                                type: "refresh",
                                size: "sm",
                                class: "text-red"
                              })
                            ], 8, _hoisted_8))
                          : _createCommentVNode("", true),
                        (msgIsNotSend.value && !_unref(connection).isOn)
                          ? (_openBlock(), _createBlock(Icon, {
                              key: 1,
                              type: "lost-connection",
                              size: "sm",
                              class: "mb-1 ml-2 text-red"
                            }))
                          : _createCommentVNode("", true)
                      ], 64))
                    : _createCommentVNode("", true),
                  (!_ctx.deleteMessageDialog)
                    ? (_openBlock(), _createBlock(RoomEventActionsPopup, { key: 1 }, {
                        default: _withCtx(() => [
                          (!msgIsNotSend.value && !redactedMessage.value)
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 0,
                                onClick: reply,
                                class: "rounded-md bg-hub-background-4 p-1 hover:bg-hub-accent"
                              }, [
                                _createVNode(Icon, {
                                  type: 'reply',
                                  size: 'xs'
                                })
                              ]))
                            : _createCommentVNode("", true),
                          (!msgIsNotSend.value && _unref(user).isAdmin && _ctx.event.sender !== _unref(user).user.userId && _unref(settings).isFeatureEnabled(_unref(FeatureFlag).disclosure))
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 1,
                                onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(router).push({ name: 'ask-disclosure', query: { user: _ctx.event.sender } }))),
                                class: "flex rounded-md bg-hub-background-4 p-1 hover:bg-hub-accent",
                                title: _ctx.$t('menu.moderation_tools_disclosure')
                              }, [
                                _createVNode(Icon, {
                                  type: 'warning',
                                  size: 'xs'
                                })
                              ], 8, _hoisted_9))
                            : _createCommentVNode("", true),
                          (_unref(settings).isFeatureEnabled(_unref(FeatureFlag).deleteMessages) && !msgIsNotSend.value && _ctx.event.sender === _unref(user).user.userId && !redactedMessage.value)
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 2,
                                onClick: _cache[2] || (_cache[2] = ($event: any) => (onDeleteMessage(_ctx.event))),
                                class: "rounded-md bg-hub-background-4 p-1 hover:bg-red",
                                title: _ctx.$t('menu.delete_message')
                              }, [
                                _createVNode(Icon, {
                                  type: 'bin',
                                  size: 'xs'
                                })
                              ], 8, _hoisted_10))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            (_ctx.event.plugin?.plugintype === _unref(PluginType).MESSAGE && _ctx.event.content.msgtype === _ctx.event.plugin.type)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.event.plugin.component), {
                  key: 0,
                  event: _ctx.event
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.event.plugin.component), 1)
                  ]),
                  _: 1
                }, 8, ["event"]))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (_openBlock(), _createBlock(_Suspense, null, {
                    fallback: _withCtx(() => [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("p", null, _toDisplayString(_ctx.$t('state.loading_message')), 1)
                      ])
                    ]),
                    default: _withCtx(() => [
                      (_unref(inReplyToId) && !redactedMessage.value)
                        ? (_openBlock(), _createBlock(MessageSnippet, {
                            key: 0,
                            class: _normalizeClass({ '!bg-[#e2e2e2]': _ctx.deleteMessageDialog }),
                            onClick: onInReplyToClick,
                            eventId: _unref(inReplyToId),
                            showInReplyTo: true,
                            room: _ctx.room
                          }, null, 8, ["class", "eventId", "room"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })),
                  (_ctx.event.content.msgtype === 'm.text' || redactedMessage.value)
                    ? (_openBlock(), _createBlock(Message, {
                        key: 0,
                        event: _ctx.event,
                        deleted: redactedMessage.value
                      }, null, 8, ["event", "deleted"]))
                    : _createCommentVNode("", true),
                  (_ctx.event.content.msgtype === 'pubhubs.signed_message' && !redactedMessage.value)
                    ? (_openBlock(), _createBlock(MessageSigned, {
                        key: 1,
                        class: _normalizeClass({ '!bg-[#e2e2e2]': _ctx.deleteMessageDialog }),
                        message: _ctx.event.content.signed_message
                      }, null, 8, ["class", "message"]))
                    : _createCommentVNode("", true),
                  (_ctx.event.content.msgtype === 'm.file' && !redactedMessage.value)
                    ? (_openBlock(), _createBlock(MessageFile, {
                        key: 2,
                        message: _ctx.event.content
                      }, null, 8, ["message"]))
                    : _createCommentVNode("", true),
                  (_ctx.event.content.msgtype === 'm.image' && !redactedMessage.value)
                    ? (_openBlock(), _createBlock(MessageImage, {
                        key: 3,
                        message: _ctx.event.content
                      }, null, 8, ["message"]))
                    : _createCommentVNode("", true)
                ], 64))
          ], 2)
        ], 2))
  ]))
}
}

})