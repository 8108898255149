import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "h-full text-hub-text"
}
const _hoisted_2 = {
  key: 0,
  class: "h-full grid-cols-8 md:grid"
}
const _hoisted_3 = { class: "flex h-full justify-between py-2" }
const _hoisted_4 = { class: "flex flex-1 items-center justify-between" }
const _hoisted_5 = { class: "flex-1 flex-col p-4" }
const _hoisted_6 = { class: "flex w-full items-center gap-2 truncate" }
const _hoisted_7 = { class: "flex h-fit w-full flex-col overflow-hidden" }
const _hoisted_8 = { class: "truncate font-bold leading-tight" }
const _hoisted_9 = { class: "leading-tight" }
const _hoisted_10 = { class: "flex flex-col gap-2" }
const _hoisted_11 = { class: "flex items-center justify-between rounded-lg bg-hub-background-4 p-2" }
const _hoisted_12 = { class: "flex items-center gap-2" }
const _hoisted_13 = {
  key: 0,
  class: "p-4"
}
const _hoisted_14 = {
  key: 1,
  class: "p-4"
}
const _hoisted_15 = { key: 1 }

import { usePubHubs } from '@/core/pubhubsStore';
	import { LOGGER } from '@/foundation/Logger';
	import { SMI } from '@/dev/StatusMessage';
	import { useDialog } from '@/store/dialog';
	import { useMenu } from '@/store/menu';
	import { MessageType } from '@/store/messagebox';
	import { usePlugins } from '@/store/plugins';
	import { RoomType } from '@/store/rooms';
	import { FeatureFlag, useSettings } from '@/store/settings';
	import { Message, MessageBoxType, useHubSettings, useMessageBox, useRooms } from '@/store/store';
	import { useUser } from '@/store/user';
	import { getCurrentInstance, onMounted, ref, watch } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { RouteParamValue, useRouter } from 'vue-router';

	// Components
	import Disclosure from '@/components/rooms/Disclosure.vue';
	import SettingsDialog from '@/components/forms/SettingsDialog.vue';
	import Dialog from '@/components/ui/Dialog.vue';
	import HeaderFooter from '@/components/ui/HeaderFooter.vue';
	import Menu from '@/components/ui/Menu.vue';
	import MenuItem from '@/components/ui/MenuItem.vue';
	import RoomList from '@/components/rooms/RoomList.vue';
	import DiscoverUsers from '@/components/rooms/DiscoverUsers.vue';
	import Badge from '@/components/elements/Badge.vue';
	import Icon from '@/components/elements/Icon.vue';
	import H2 from '@/components/elements/H2.vue';
	import Avatar from '@/components/ui/Avatar.vue';
	import { HubInformation } from '@/store/hub-settings';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

	const { locale, availableLocales } = useI18n();
	const router = useRouter();
	const settings = useSettings();
	const hubSettings = useHubSettings();
	const user = useUser();
	const rooms = useRooms();
	const messagebox = useMessageBox();
	const dialog = useDialog();
	const pubhubs = usePubHubs();
	const plugins = usePlugins();
	const menu = useMenu();
	const settingsDialog = ref(false);
	const setupReady = ref(false);
	const disclosureEnabled = settings.isFeatureEnabled(FeatureFlag.disclosure);

	watch(
		() => rooms.totalUnreadMessages,
		() => {
			rooms.sendUnreadMessageCounter();
		},
	);

	onMounted(() => {
		plugins.setPlugins(getCurrentInstance()?.appContext.config.globalProperties._plugins, router);
	});

	onMounted(async () => {
		LOGGER.trace(SMI.STARTUP, 'App.vue onMounted');

		settings.initI18b({ locale: locale, availableLocales: availableLocales });
		// set language when changed
		settings.$subscribe(() => {
			locale.value = settings.getActiveLanguage;
		});

		// check if hash doesn't start with hub,
		// then it is running only the hub-client, so we need to do some checks
		if (!window.location.hash.startsWith('#/hub/')) {
			pubhubs.login().then(() => (setupReady.value = true));
			// Needs onboarding?
			if (user.needsOnboarding) {
				router.push({ name: 'onboarding' });
			} else {
				router.push({ name: 'home' });
			}
			// 2024 12 03 The await is removed, because of slow loading testhub
			// After the next merge to stable, in case this gives no problems,
			// the old code and comments can be removed
			// If all works well: setupReady can also be removed, since it does have no function anymmore
			// await pubhubs.login();
			// setupReady.value = true; // needed if running only the hub-client
			// router.push({ name: 'home' });
		}

		if (!user.isLoggedIn) {
			// only needed when loggedIn (then there are user settings to setup)
			setupReady.value = true;
		}
		await startMessageBox();

		LOGGER.trace(SMI.STARTUP, 'App.vue onMounted done');
	});

	async function startMessageBox() {
		if (!hubSettings.isSolo) {
			await messagebox.init(MessageBoxType.Child, hubSettings.parentUrl);

			// Ask for Hub name etc.
			messagebox.addCallback(MessageType.HubInformation, (message: Message) => {
				hubSettings.initHubInformation(message.content as HubInformation);
			});

			// Listen to roomchange
			messagebox.addCallback(MessageType.RoomChange, async (message: Message) => {
				const roomId = message.content as RouteParamValue;
				if (rooms.currentRoomId !== roomId) {
					rooms.currentRoomId = roomId;
					await rooms.getSecuredRoomInfo(roomId);
					if (rooms.securedRoom && rooms.securedRoom !== null) {
						router.push({ name: 'secure-room', params: { id: roomId } });
					} else {
						router.push({ name: 'room', params: { id: roomId } });
					}
				}
			});

			//Listen to global menu change
			messagebox.addCallback(MessageType.BarHide, () => {
				hubSettings.mobileHubMenu = false;
			});

			messagebox.addCallback(MessageType.BarShow, () => {
				hubSettings.mobileHubMenu = true;
			});
		}
	}

return (_ctx: any,_cache: any) => {
  const _component_H1 = _resolveComponent("H1")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_unref(settings).getActiveTheme, "h-full"])
  }, [
    (setupReady.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_unref(user).isLoggedIn)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(HeaderFooter, {
                  class: _normalizeClass(["bg-hub-background-2 md:col-span-2 md:flex", { hidden: !_unref(hubSettings).mobileHubMenu }]),
                  headerBgColor: 'bg-hub-background-3'
                }, {
                  header: _withCtx(() => [
                    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "flex items-center gap-4" }, [
                      _createElementVNode("span", { class: "text-xxs font-bold uppercase" }, "hub"),
                      _createElementVNode("hr", { class: "grow" })
                    ], -1)),
                    _createElementVNode("div", _hoisted_3, [
                      (_unref(hubSettings).isSolo && _unref(settings).isFeatureEnabled(_unref(FeatureFlag).notifications) && _unref(rooms).totalUnreadMessages > 0)
                        ? (_openBlock(), _createBlock(Badge, {
                            key: 0,
                            class: "-ml-4 -mt-2 w-8 flex-none"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_unref(rooms).totalUnreadMessages), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_H1, {
                          class: "line-clamp-1",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/'))),
                          title: _unref(hubSettings).hubName
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(hubSettings).hubName), 1)
                          ]),
                          _: 1
                        }, 8, ["title"]),
                        _createVNode(Icon, {
                          type: "cog",
                          size: "sm",
                          class: "hidden rounded-md bg-hub-background-2 p-2"
                        })
                      ])
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("section", null, [
                        _createElementVNode("div", {
                          onClick: _cache[1] || (_cache[1] = ($event: any) => {
									settingsDialog.value = true;
									_unref(hubSettings).hideBar();
								}),
                          class: "flex cursor-pointer items-center justify-between overflow-hidden rounded-lg bg-hub-background-3 p-2 text-hub-text"
                        }, [
                          _createElementVNode("div", _hoisted_6, [
                            _createVNode(Avatar, {
                              user: _unref(user),
                              img: _unref(user).avatarUrl
                            }, null, 8, ["user", "img"]),
                            _createElementVNode("div", _hoisted_7, [
                              _createElementVNode("p", _hoisted_8, _toDisplayString(_unref(user).displayName), 1),
                              _createElementVNode("p", _hoisted_9, _toDisplayString(_unref(user).pseudonym ?? ''), 1)
                            ])
                          ]),
                          _createVNode(Icon, {
                            type: "pencil",
                            size: "sm",
                            class: "rounded-md stroke-0 p-2"
                          })
                        ]),
                        _createVNode(Menu, null, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menu).getMenu, (item, index) => {
                              return (_openBlock(), _createBlock(MenuItem, {
                                key: index,
                                to: item.to,
                                icon: item.icon,
                                onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(hubSettings).hideBar()))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t(item.key)), 1)
                                ]),
                                _: 2
                              }, 1032, ["to", "icon"]))
                            }), 128))
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("section", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, [
                          _createVNode(H2, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('menu.rooms')), 1)
                            ]),
                            _: 1
                          }),
                          _createElementVNode("div", _hoisted_12, [
                            _createVNode(_component_router_link, { to: { name: 'discover-rooms' } }, {
                              default: _withCtx(() => [
                                _createVNode(Icon, {
                                  type: "compass",
                                  size: "md"
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(Icon, {
                              class: "hidden stroke-0",
                              type: "dots",
                              size: "sm"
                            })
                          ])
                        ]),
                        _createVNode(RoomList)
                      ]),
                      _createElementVNode("section", null, [
                        _createVNode(H2, { class: "" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('menu.private_rooms')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(RoomList, {
                          roomType: _unref(RoomType).PH_MESSAGES_DM
                        }, null, 8, ["roomType"]),
                        _createVNode(DiscoverUsers)
                      ])
                    ]),
                    (_unref(disclosureEnabled) && _unref(user).isAdmin)
                      ? (_openBlock(), _createElementBlock("section", _hoisted_13, [
                          _createVNode(H2, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('menu.moderation_tools')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(Menu, null, {
                            default: _withCtx(() => [
                              _createVNode(MenuItem, {
                                to: { name: 'ask-disclosure' },
                                icon: "sign"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('menu.moderation_tools_disclosure')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]))
                      : _createCommentVNode("", true),
                    (_unref(user).isAdmin)
                      ? (_openBlock(), _createElementBlock("section", _hoisted_14, [
                          _createVNode(H2, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('menu.admin_tools')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(Menu, null, {
                            default: _withCtx(() => [
                              _createVNode(MenuItem, {
                                to: { name: 'admin' },
                                icon: "admin"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('menu.admin_tools_rooms')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          (_unref(settings).isFeatureEnabled(_unref(FeatureFlag).hubSettings))
                            ? (_openBlock(), _createBlock(Menu, { key: 0 }, {
                                default: _withCtx(() => [
                                  _createVNode(MenuItem, {
                                    to: { name: 'hub-settings' },
                                    icon: "cog"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('menu.admin_tools_hub_settings')), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["class"]),
                _createElementVNode("div", {
                  class: _normalizeClass(["scrollbar h-full overflow-y-auto dark:bg-gray-middle md:col-span-6 md:block", { hidden: _unref(hubSettings).mobileHubMenu }])
                }, [
                  _createVNode(_component_router_view)
                ], 2)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createVNode(_component_router_view)
              ]))
        ]))
      : _createCommentVNode("", true),
    (_unref(disclosureEnabled))
      ? (_openBlock(), _createBlock(Disclosure, { key: 1 }))
      : _createCommentVNode("", true),
    (settingsDialog.value)
      ? (_openBlock(), _createBlock(SettingsDialog, {
          key: 2,
          onClose: _cache[3] || (_cache[3] = ($event: any) => (settingsDialog.value = false))
        }))
      : _createCommentVNode("", true),
    (_unref(dialog).visible)
      ? (_openBlock(), _createBlock(Dialog, {
          key: 3,
          onClose: _unref(dialog).close
        }, null, 8, ["onClose"]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})