import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, withKeys as _withKeys, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-row"
}

import TextInput from './TextInput.vue';
	import Icon from '../elements/Icon.vue';

	import { ref } from 'vue';
	import { useFormInputEvents, usedEvents } from '@/composables/useFormInputEvents';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'LineInput',
  props: {
		placeholder: {
			type: String,
			default: 'input',
		},
		visible: {
			type: Boolean,
			default: true,
		},
	},
  emits: usedEvents,
  setup(__props, { emit: __emit }) {

	// Components
	const props = __props;

	const emit = __emit;
	const { value, changed, submit, cancel } = useFormInputEvents(emit);

	let isFormVisible = ref(props.visible);

	function showForm() {
		isFormVisible.value = true;
	}

	function resetVisibility() {
		isFormVisible.value = props.visible;
	}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_unref(isFormVisible))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(TextInput, {
            class: "grow",
            modelValue: _unref(value),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
            placeholder: __props.placeholder,
            onKeydown: [
              _cache[1] || (_cache[1] = ($event: any) => (_unref(changed)())),
              _cache[2] || (_cache[2] = _withKeys(($event: any) => {
					_unref(submit)();
					resetVisibility();
				}, ["enter"])),
              _cache[3] || (_cache[3] = _withKeys(($event: any) => {
					_unref(cancel)();
					resetVisibility();
				}, ["esc"]))
            ]
          }, null, 8, ["modelValue", "placeholder"]),
          _createVNode(Icon, {
            type: "plus",
            size: "lg",
            class: "m-2 cursor-pointer",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(submit)()))
          })
        ]))
      : (_openBlock(), _createBlock(Icon, {
          key: 1,
          type: "plus",
          class: "cursor-pointer",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (showForm()))
        }))
  ]))
}
}

})