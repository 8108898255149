import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, isRef as _isRef, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["value", "onChange"]
const _hoisted_2 = ["for"]

import { PropType } from 'vue';
	import { Options, useFormInputEvents, usedEvents } from '@/composables/useFormInputEvents';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'Radio',
  props: {
		options: {
			type: Array as PropType<Options>,
			required: true,
		},
		value: {
			type: String,
			default: '',
		},
	},
  emits: usedEvents,
  setup(__props, { emit: __emit }) {

	const props = __props;

	const emit = __emit;
	const { value: inputValue, setValue, setOptions, selectOption, changed } = useFormInputEvents(emit);

	setValue(props.value);
	setOptions(props.options);

return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (option) => {
    return (_openBlock(), _createElementBlock("ul", {
      key: option.value
    }, [
      _createElementVNode("li", null, [
        _withDirectives(_createElementVNode("input", {
          type: "radio",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(inputValue) ? (inputValue).value = $event : null)),
          value: option.value,
          class: "focus:ring-offset-width-0 focus:shadow-0 focus:outline-0 focus:outline-offset-0 focus:ring-0 focus:ring-offset-0",
          onChange: ($event: any) => {
					_unref(selectOption)(option);
					_unref(changed)();
				}
        }, null, 40, _hoisted_1), [
          [_vModelRadio, _unref(inputValue)]
        ]),
        _createElementVNode("label", {
          class: "ml-2 dark:text-white",
          for: option.value
        }, _toDisplayString(option.label), 9, _hoisted_2)
      ])
    ]))
  }), 128))
}
}

})