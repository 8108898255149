import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "relative hidden items-center md:flex" }
const _hoisted_2 = ["placeholder", "title"]
const _hoisted_3 = { class: "cursor-pointer" }
const _hoisted_4 = { class: "absolute flex h-full w-full items-center justify-end pr-2 md:hidden" }
const _hoisted_5 = { class: "relative flex w-[35px] max-w-full items-center justify-end gap-4 rounded-md transition-all duration-200 focus-within:w-full focus-within:bg-hub-background-4 focus-within:dark:bg-hub-background-3" }
const _hoisted_6 = ["placeholder", "title"]
const _hoisted_7 = {
  key: 0,
  class: "scrollbar absolute right-2 top-16 max-h-[500%] w-full max-w-80 overflow-y-auto rounded-b-md bg-gray-lighter dark:bg-gray-darker md:right-0 md:top-20"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "flex gap-2 p-2 group-hover:bg-gray-light group-hover:dark:bg-gray" }
const _hoisted_10 = {
  key: 0,
  class: "p-2"
}
const _hoisted_11 = { class: "flex gap-2 p-2 group-hover:bg-gray-light group-hover:dark:bg-gray" }

import Avatar from '../ui/Avatar.vue';
	import Icon from '../elements/Icon.vue';
	import InlineSpinner from '../ui/InlineSpinner.vue';

	import { useFormInputEvents, usedEvents } from '@/composables/useFormInputEvents';
	import { filterAlphanumeric } from '@/core/extensions';
	import { usePubHubs } from '@/core/pubhubsStore';
	import Room from '@/model/rooms/Room';
	import { useRooms } from '@/store/store';
	import { ISearchResults, SearchResult } from 'matrix-js-sdk';
	import { PropType, computed, ref } from 'vue';
	import TruncatedText from '../elements/TruncatedText.vue';
	import { TSearchParameters, TSearchResult } from '@/model/search/TSearch';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'SearchInput',
  props: {
		searchParameters: {
			type: Object as PropType<TSearchParameters>,
			required: true,
		},
		room: Room,
	},
  emits: [...usedEvents, 'scrollToEventId'],
  setup(__props, { emit: __emit }) {

	// Components
	const pubhubs = usePubHubs();
	const rooms = useRooms();

	//Passed by the parentcomponent
	const props = __props;

	const searchResults = ref<TSearchResult[]>([]);
	const searched = ref(false);
	const isSearching = ref(false);
	let searchResponse: ISearchResults | undefined = undefined;

	const emit = __emit;
	const { value, changed, cancel } = useFormInputEvents(emit);

	// searchresults shown in list. When the text 'more results' is shown the last result is omitted to keep it in view
	const searchResultsToShow = computed(() => {
		// Only results that do not have an empty event_body should be shown
		const filteredSearchResults = searchResults.value.filter((result) => result.event_body !== '');
		return filteredSearchResults; // Return all items
	});

	async function search() {
		searchResults.value = [];
		searched.value = true;
		isSearching.value = true;
		if (!value.value) return;

		try {
			searchResponse = await pubhubs.searchRoomEvents(value.value as string, props.searchParameters);
		} catch (err) {
			isSearching.value = false;
			console.error('An error occurred while searching the room: ', err);
		}

		if (searchResponse && searchResponse.next_batch) {
			while (searchResponse.next_batch) {
				searchResponse = await pubhubs.backPaginateRoomEventsSearch(searchResponse);
			}
		}
		if (searchResponse && searchResponse.results.length > 0) {
			searchResults.value = mapSearchResult(searchResponse.results);
		}
		isSearching.value = false;
	}

	function reset() {
		searchResults.value = [];
		searched.value = false;
	}

	async function onScrollToEventId(eventId: string) {
		if (props.searchParameters.roomId && rooms.currentRoom!.roomId === props.searchParameters.roomId) {
			emit('scrollToEventId', { eventId: eventId });
			// reset();
		}
	}

	function mapSearchResult(results: SearchResult[]): TSearchResult[] {
		if (!results || results.length == 0) {
			return [];
		}
		let mappedResults = results.map(
			(result) =>
				({
					rank: result.rank,
					event_id: result.context.ourEvent.event.event_id!,
					event_type: result.context.ourEvent.event.type,
					event_body: result.context.ourEvent.event.content?.body,
					event_sender: result.context.ourEvent.event.sender,
				}) as TSearchResult,
		);
		mappedResults.forEach((element) => {
			element.event_body = formatSearchResult(element.event_body, value.value as string, 5);
		});
		return mappedResults;
	}

	function formatSearchResult(eventbody: string, searchterm: string, numberOfWords: number): string {
		if (!eventbody || !searchterm) return '';

		var words = filterAlphanumeric(eventbody)?.toLowerCase().split(/\s+/);
		var searchWords = filterAlphanumeric(searchterm.trim())?.toLowerCase().split(/\s+/);

		if (!words || !searchWords) return '';

		// Compare the words to the searchterm.
		// if searchterm is fully found, index will be > -1
		// if searchterm is not found, index will be -1
		var index = -1;
		for (var i = 0; i < words.length; i++) {
			if (words[i] === searchWords[0]) {
				index = i;
				for (var j = 1; j < searchWords.length; j++) {
					// If the words do not match, reset the index to -1 and break out of the loop
					if (words[i + j] !== searchWords[j]) {
						index = -1;
						break;
					}
				}
				// If the index is not -1 after the loop, the search term was found
				if (index !== -1) {
					break;
				}
			}
		}

		if (index === -1) {
			return '';
		}

		var start = Math.max(0, index - numberOfWords);
		var end = Math.min(eventbody.split(' ').length, index + searchWords.length + numberOfWords);

		return eventbody.split(' ').slice(start, end).join(' ');
	}

return (_ctx: any,_cache: any) => {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
      _withDirectives(_createElementVNode("input", {
        class: "w-full min-w-48 rounded-md border-none bg-gray-lighter py-1 placeholder:text-black focus:border-black focus:outline-0 focus:outline-offset-0 focus:ring-0 dark:bg-gray-darker dark:text-white dark:placeholder:text-gray-light md:pr-8",
        type: "text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
        placeholder: _ctx.$t('others.search_room'),
        title: _ctx.$t('others.search_room'),
        onKeydown: [
          _cache[1] || (_cache[1] = ($event: any) => {
				_unref(changed)();
				reset();
			}),
          _cache[2] || (_cache[2] = _withKeys(($event: any) => (search()), ["enter"])),
          _cache[3] || (_cache[3] = _withKeys(($event: any) => {
				_unref(cancel)();
				reset();
			}, ["esc"]))
        ]
      }, null, 40, _hoisted_2), [
        [_vModelText, _unref(value)]
      ]),
      _createElementVNode("span", _hoisted_3, [
        _createVNode(Icon, {
          class: "search-icon -ml-6 dark:text-gray-light",
          type: "search",
          size: "sm",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (search()))
        })
      ])
    ])), [
      [_directive_click_outside, reset]
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _withDirectives(_createElementVNode("input", {
          class: "h-10 w-full flex-1 border-none bg-transparent placeholder:text-black focus:outline-0 focus:outline-offset-0 focus:ring-0 dark:text-white dark:placeholder:text-gray-light",
          type: "text",
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
          placeholder: _ctx.$t('others.search_room'),
          title: _ctx.$t('others.search_room'),
          onKeydown: [
            _cache[6] || (_cache[6] = ($event: any) => {
					_unref(changed)();
					reset();
				}),
            _cache[7] || (_cache[7] = _withKeys(($event: any) => (search()), ["enter"])),
            _cache[8] || (_cache[8] = _withKeys(($event: any) => {
					_unref(cancel)();
					reset();
				}, ["esc"]))
          ]
        }, null, 40, _hoisted_6), [
          [_vModelText, _unref(value)]
        ]),
        _createElementVNode("button", {
          class: "flex cursor-pointer items-center justify-center rounded-md px-1",
          onClick: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (search()), ["stop"]))
        }, [
          _createVNode(Icon, {
            class: "search-icon px-1",
            type: "search",
            size: "md"
          })
        ])
      ])
    ]),
    (searched.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (searchResultsToShow.value && searchResultsToShow.value.length > 0)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(searchResultsToShow.value, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.event_id,
                  class: "group"
                }, [
                  _createElementVNode("a", {
                    href: "#",
                    onClick: _withModifiers(($event: any) => (onScrollToEventId(item.event_id)), ["prevent"])
                  }, [
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(Avatar, {
                        user: __props.room?.getMember(item.event_sender, true),
                        class: "h-6 w-6 flex-none"
                      }, null, 8, ["user"]),
                      _createVNode(TruncatedText, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.event_body), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ], 8, _hoisted_8)
                ]))
              }), 128))
            : (isSearching.value)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createVNode(InlineSpinner, { class: "float-left mr-2" }),
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('others.searching')), 1)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  (_unref(value) !== '')
                    ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.$t('others.search_nothing_found')), 1))
                    : _createCommentVNode("", true)
                ], 64)),
          (searchResults.value && searchResults.value.length > 0 && _unref(searchResponse) && _unref(searchResponse).count && _unref(searchResponse).count > searchResults.value.length)
            ? (_openBlock(), _createElementBlock("a", {
                key: 3,
                href: "#",
                onClick: _cache[10] || (_cache[10] = _withModifiers(($event: any) => (_ctx.loadMoreSearchResults()), ["prevent"]))
              }, [
                _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('others.load_more_results')), 1)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})