import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

import { useRouter } from 'vue-router';
	import { useMenu } from '@/store/menu';
	import { Room } from '@/store/rooms';
	import { useRooms } from '@/store/store';
	import { computed } from 'vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'MenuItem',
  props: {
		to: {
			type: [String, Object],
			default: '',
		},
		icon: {
			type: String,
			default: 'circle',
		},
		roomInfo: {
			type: [Room, Object],
			default: Object,
		},
	},
  setup(__props) {

	const router = useRouter();
	const rooms = useRooms();
	const menu = useMenu();

	const isActive = computed(() => {
		if (props.roomInfo?.roomId) {
			return props.roomInfo?.roomId === menu.activeMenuItemId;
		} else {
			return false;
		}
	});

	const props = __props;

	function isSecuredRoom() {
		if (props.roomInfo?.roomId !== undefined) {
			return rooms.roomIsSecure(props.roomInfo.roomId);
		}
		return false;
	}

	function click() {
		router.push(props.to);
	}

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_TruncatedText = _resolveComponent("TruncatedText")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass([{ 'bg-lightgray dark:bg-gray-middle': isActive.value }, "menu-item h-11 rounded-lg py-2 pl-5 transition-all duration-150 ease-in-out hover:bg-lightgray hover:dark:bg-gray-middle"]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => {
			click();
			_unref(menu).setActiveMenuItem(props.roomInfo?.roomId);
		})
  }, [
    _createVNode(_component_router_link, {
      to: __props.to,
      class: "flex items-center gap-2"
    }, {
      default: _withCtx(() => [
        (isSecuredRoom())
          ? (_openBlock(), _createBlock(_component_Icon, {
              key: 0,
              type: "shield"
            }))
          : (_openBlock(), _createBlock(_component_Icon, {
              key: 1,
              class: "dark:text-white",
              type: __props.icon
            }, null, 8, ["type"])),
        _createVNode(_component_TruncatedText, { class: "w-full" }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        })
      ]),
      _: 3
    }, 8, ["to"])
  ], 2))
}
}

})