import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withModifiers as _withModifiers, withKeys as _withKeys, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["maxlength", "placeholder", "title", "value", "disabled"]

import { ref } from 'vue';
	import { useFormInputEvents, usedEvents } from '@/composables/useFormInputEvents';
	import { getCaretPos as domGetCaretPos } from '@/lib/domUtility';
	import { Ref } from 'vue';

	type Props = {
		modelValue: string;
		placeholder?: string;
		maxLength?: number;
		disabled?: boolean;
	};
	
export default /*@__PURE__*/_defineComponent({
  __name: 'TextArea',
  props: {
    modelValue: {},
    placeholder: { default: '' },
    maxLength: { default: 1500 },
    disabled: { type: Boolean, default: false }
  },
  emits: [...usedEvents, 'caretPos'],
  setup(__props: any, { emit: __emit }) {

	const elTextarea: Ref<null | HTMLTextAreaElement> = ref(null);

	const props = __props;

	const emit = __emit;
	const { update, changed, submit, cancel } = useFormInputEvents(emit, props.modelValue);

	function onKeyUp() {
		changed();
		emit('caretPos', getCaretPos());
		resize();
	}

	function getCaretPos() {
		if (!elTextarea.value) return;
		return domGetCaretPos(elTextarea.value);
	}

	/**
	 * Resizing the textarea accordingly to its content.
	 * The 'auto' height minimizes the textarea before it increases it again.
	 */
	function resize() {
		if (!elTextarea.value) return;
		elTextarea.value.style.height = 'auto';
		elTextarea.value.style.height = elTextarea.value.scrollHeight + 'px';
	}

return (_ctx: any,_cache: any) => {
  const _directive_tw_class = _resolveDirective("tw-class")!

  return _withDirectives((_openBlock(), _createElementBlock("textarea", {
    ref_key: "elTextarea",
    ref: elTextarea,
    rows: "1",
    class: "w-full resize-none rounded-lg border focus:border-black focus:outline-0 focus:outline-offset-0 focus:ring-0 theme-light:border-black theme-light:text-gray-dark dark:border-white dark:text-white",
    maxlength: _ctx.maxLength,
    placeholder: _ctx.placeholder,
    title: _ctx.placeholder,
    value: _ctx.modelValue,
    disabled: _ctx.disabled === true,
    onInput: _cache[0] || (_cache[0] = ($event: any) => (_unref(update)(($event.target as HTMLTextAreaElement).value))),
    onKeyup: onKeyUp,
    onKeydown: [
      _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_unref(submit)()), ["exact"]), ["enter"])),
      _cache[2] || (_cache[2] = _withKeys(($event: any) => (_unref(cancel)()), ["esc"]))
    ]
  }, null, 40, _hoisted_1)), [
    [_directive_tw_class, 'p-2']
  ])
}
}

})