import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between" }
const _hoisted_2 = ["title"]

import Dialog from '../ui/Dialog.vue';
	import FilteredList from '../ui/FilteredList.vue';
	import Icon from '../elements/Icon.vue';

	import { usePubHubs } from '@/core/pubhubsStore';
	import { buttonsCancel } from '@/store/dialog';
	import { useUser } from '@/store/user';
	import { FilteredListEvent } from '@/types/components';
	import { User as MatrixUser } from 'matrix-js-sdk';
	import { computed, onMounted, ref } from 'vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'ChooseFromUsersList',
  props: {
    header: {}
  },
  emits: ['chosenUser', 'close'],
  setup(__props: any, { emit: __emit }) {

	// Components
	const pubhubs = usePubHubs();
	const user = useUser();
	const emit = __emit;

	const props = __props;

	const users = ref([] as Array<MatrixUser>);

	onMounted(async () => {
		users.value = await pubhubs.getUsers();
	});

	const usersList = computed(() => {
		let list = users.value as any;
		list = list.map((user: any) => {
			return {
				userId: user.userId,
				displayName: user.displayName,
				avatarUrl: user.avatarUrl,
			};
		});
		// Remove self from list
		list = list.filter((u: any) => u.userId !== user.user.userId && u.rawDisplayName !== 'notices');
		return list;
	});

	async function onUser(other: any) {
		emit('chosenUser', other);
	}

	async function filter(event: FilteredListEvent) {
		if (event.length < 10) {
			let foundUsers = await pubhubs.findUsers(event.filter);
			foundUsers = foundUsers.map((user) => {
				user.userId = user.user_id;
				user.displayName = user.display_name;
				user.avatarUrl = user.avatar_url;
				return user;
			});
			// combine and unique
			users.value = [...users.value, ...foundUsers];
			users.value = users.value.filter((user, index, arr) => {
				return arr.findIndex((item) => item.userId === user.userId) === index;
			});
		}
	}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Dialog, {
    buttons: _unref(buttonsCancel),
    width: "w-3/6"
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.header), 1)
    ]),
    default: _withCtx(() => [
      _createVNode(FilteredList, {
        items: usersList.value,
        filterKey: ['displayName'],
        placeholder: _ctx.$t('rooms.private_search_user'),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (onUser($event))),
        onFilter: _cache[1] || (_cache[1] = ($event: any) => (filter($event)))
      }, {
        item: _withCtx(({ item }) => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", {
              title: item.userId,
              class: "w-100 grow truncate"
            }, _toDisplayString(item.displayName), 9, _hoisted_2),
            _createVNode(Icon, {
              type: "plus",
              class: "flex-none"
            })
          ])
        ]),
        _: 1
      }, 8, ["items", "placeholder"])
    ]),
    _: 1
  }, 8, ["buttons"]))
}
}

})