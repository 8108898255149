import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src"]

import { useUserColor } from '@/composables/useUserColor';
	import RoomMember from '@/model/rooms/RoomMember';
	import { CurrentUser, useUser } from '@/store/user';
	import { useMatrixFiles } from '@/composables/useMatrixFiles';
	import { computed, watch, onMounted, ref } from 'vue';

	//Components
	import Icon from '../elements/Icon.vue';
	import { FeatureFlag, useSettings } from '@/store/settings';

	type Props = {
		user: RoomMember | CurrentUser | null | undefined;
		overrideAvatarUrl?: string | undefined;
	};

	const backgroundColor = 'bg-hub-background-4';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'Avatar',
  props: {
    user: {},
    overrideAvatarUrl: {}
  },
  setup(__props: any) {

	const { color, bgColor } = useUserColor();

	const settings = useSettings();
	const matrixFiles = useMatrixFiles();

	const currentUser = useUser();

	const props = __props;

	const imageUrl = computed(getImageUrl);
	const avatarColor = computed(getAvatarColor);

	const authMediaUrl = ref<string | undefined>(undefined);

	onMounted(async () => {
		// The nullish coalescing operator (??) to provide a default value of an empty string if overrideAvatarUrl is undefined
		await setAuthenticatedMediaUrl(props.overrideAvatarUrl ?? '');
	});

	watch(
		() => props.overrideAvatarUrl,
		async (newURL) => {
			await setAuthenticatedMediaUrl(newURL ?? '');
		},
	);

	async function setAuthenticatedMediaUrl(matrixURL: string): Promise<void> {
		// If the user has removed the url then authMediaURL should be undefined.
		authMediaUrl.value = matrixURL !== '' ? await matrixFiles.useAuthorizedMediaUrl(matrixURL, settings.isFeatureEnabled(FeatureFlag.authenticatedMedia)) : undefined;
	}

	function getImageUrl(): string | undefined | null {
		if (!props.user) return undefined;
		if (props.overrideAvatarUrl !== undefined) return authMediaUrl.value;
		return userIsCurrentUser() ? currentUser.avatarUrl : props.user.avatarUrl;
	}

	function getAvatarColor(): string {
		if (!props.user?.userId) return backgroundColor;

		if (imageUrl.value) {
			return backgroundColor;
		} else {
			return bgColor(color(props.user.userId));
		}
	}

	function userIsCurrentUser(): boolean {
		return props.user?.userId === currentUser.userId;
	}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex aspect-square h-12 w-12 shrink-0 items-center justify-center overflow-hidden rounded-full", avatarColor.value])
  }, [
    (imageUrl.value)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          "data-testid": "avatar",
          src: imageUrl.value,
          class: "h-full w-full"
        }, null, 8, _hoisted_1))
      : (_openBlock(), _createBlock(Icon, {
          key: 1,
          size: "lg",
          type: "person"
        }))
  ], 2))
}
}

})