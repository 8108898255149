import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex w-full items-center justify-between gap-2" }
const _hoisted_2 = {
  key: 0,
  class: "flex gap-2 transition-all duration-200 ease-in-out group-hover:hidden"
}

import InlineSpinner from '../ui/InlineSpinner.vue';
	import Menu from '../ui/Menu.vue';
	import MenuItem from '../ui/MenuItem.vue';
	import TruncatedText from '../elements/TruncatedText.vue';
	import PrivateRoomName from './PrivateRoomName.vue';
	import RoomName from './RoomName.vue';
	import Badge from '../elements/Badge.vue';
	import Icon from '../elements/Icon.vue';

	import { isVisiblePrivateRoom } from '@/core/privateRoomNames';
	import { usePubHubs } from '@/core/pubhubsStore';
	import { PluginProperties, usePlugins } from '@/store/plugins';
	import { RoomType } from '@/store/rooms';
	import { FeatureFlag, useSettings } from '@/store/settings';
	import { useDialog, useHubSettings, useRooms } from '@/store/store';
	import { useUser } from '@/store/user';
	import { NotificationCountType } from 'matrix-js-sdk';
	import { Room } from '@/store/rooms';
	import { useI18n } from 'vue-i18n';
	import { useRouter } from 'vue-router';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'RoomList',
  props: {
		roomType: {
			type: String,
			default: '!' + RoomType.PH_MESSAGES_DM,
		},
	},
  setup(__props) {

	// Components
	const settings = useSettings();
	const hubSettings = useHubSettings();

	const { t } = useI18n();
	const router = useRouter();
	const rooms = useRooms();
	const pubhubs = usePubHubs();
	const plugins = usePlugins();

	const props = __props;

	// Either private room or public room based on roomType given as prop (private or normal)
	// Needs a bit of refacturing, not so clear now.
	function showRoom(room: Room): Boolean {
		const roomType = room.getType();

		// if no specific type is set, allways show this room
		if (props.roomType !== '') {
			const type = props.roomType.substring(1);
			// If not (given room type), just show
			if (props.roomType.charAt(0) === '!') {
				return roomType !== type;
			} else {
				if (roomType === RoomType.PH_MESSAGES_DM) {
					// Check if private room is visible for this user BUSY
					const user = useUser();
					return isVisiblePrivateRoom(room.name, user.user);
				} else {
					return roomType === props.roomType;
				}
			}
		}
		return true;
	}

	async function leaveRoom(roomId: string) {
		const room = rooms.room(roomId);
		if (room) {
			const dialog = useDialog();
			if (room.isPrivateRoom()) {
				if (await dialog.okcancel(t('rooms.hide_sure'))) {
					await pubhubs.setPrivateRoomHiddenStateForUser(room, true);
					await router.replace({ name: 'home' });
				}
			} else {
				if (await dialog.okcancel(t('rooms.leave_sure'))) {
					await pubhubs.leaveRoom(roomId);
					await router.replace({ name: 'home' });
				}
			}
		}
	}

	function roomIcon(room: Room): string {
		let icon = 'speech_bubbles';
		const plugin = plugins.hasRoomPlugin(room) as PluginProperties;
		if (plugin.icon) {
			icon = plugin.icon;
		}
		return icon;
	}

return (_ctx: any,_cache: any) => {
  return (!_unref(rooms).roomsLoaded)
    ? (_openBlock(), _createBlock(InlineSpinner, {
        key: 0,
        class: "ml-4"
      }))
    : (_openBlock(), _createBlock(Menu, { key: 1 }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(rooms).sortedRoomsArray, (room) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: room.roomId
            }, [
              (showRoom(room))
                ? (_openBlock(), _createBlock(MenuItem, {
                    to: { name: 'room', params: { id: room.roomId } },
                    roomInfo: room,
                    icon: roomIcon(room),
                    key: room.roomId,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(hubSettings).hideBar())),
                    class: "group inline-block w-full"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_1, [
                        _createVNode(TruncatedText, null, {
                          default: _withCtx(() => [
                            (room.isPrivateRoom())
                              ? (_openBlock(), _createBlock(PrivateRoomName, {
                                  key: 0,
                                  members: room.getOtherJoinedAndInvitedMembers()
                                }, null, 8, ["members"]))
                              : (_openBlock(), _createBlock(RoomName, {
                                  key: 1,
                                  room: room
                                }, null, 8, ["room"]))
                          ]),
                          _: 2
                        }, 1024),
                        (_unref(settings).isFeatureEnabled(_unref(FeatureFlag).notifications))
                          ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                              (room.getRoomUnreadNotificationCount(_unref(NotificationCountType).Total) > 99)
                                ? (_openBlock(), _createBlock(Badge, {
                                    key: 0,
                                    class: "text-xxs",
                                    color: "hub"
                                  }, {
                                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                                      _createTextVNode("99+")
                                    ])),
                                    _: 1
                                  }))
                                : (room.getRoomUnreadNotificationCount(_unref(NotificationCountType).Total) > 0)
                                  ? (_openBlock(), _createBlock(Badge, {
                                      key: 1,
                                      color: "hub"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(room.getRoomUnreadNotificationCount(_unref(NotificationCountType).Total)), 1)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : _createCommentVNode("", true),
                              (room.getRoomUnreadNotificationCount(_unref(NotificationCountType).Highlight) > 0)
                                ? (_openBlock(), _createBlock(Badge, {
                                    key: 2,
                                    color: "hub"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(Icon, {
                                        type: "mention",
                                        size: "sm",
                                        class: "shrink-0"
                                      })
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true),
                        _createVNode(Icon, {
                          type: "unlink",
                          class: "hidden cursor-pointer stroke-2 transition-all duration-200 ease-in-out hover:text-red-light group-hover:inline-block",
                          onClick: _withModifiers(($event: any) => (leaveRoom(room.roomId)), ["prevent"])
                        }, null, 8, ["onClick"])
                      ])
                    ]),
                    _: 2
                  }, 1032, ["to", "roomInfo", "icon"]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]),
        _: 1
      }))
}
}

})